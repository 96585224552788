import useJobs from "@/hooks/useJobs";
import { Buttons } from "@/ui/Buttons";
import { Carousel } from "@/ui/Carousel";
import { JobCard } from "@/ui/JobCard";
import { ParallaxBackground } from "@/ui/ParallaxBackground";
import cn from "classnames";
import { Container, Stack } from "react-bootstrap";

import bg_1 from "./bg_1.webp";
import bg_2 from "./bg_2.webp";
import bg_3 from "./bg_3.webp";
import bg_4 from "./bg_4.webp";
import classes from "./styles.module.scss";

export default function ({
  className = "py-5 py-md-6",
  title,
  description,
  buttons = [
    {
      label: "View All Jobs",
      url: "/jobs",
      variant: "navy",
    },
  ],
  prefix,
  EditableText,
  EditableHtml,
  theme = "light",
  __sector,
}) {
  const jobs = useJobs({ __sector, limit: 12 });

  if (jobs.length === 0) return null;

  return (
    <div
      className={cn(className, classes.JobsCarousel)}
      data-bs-theme={theme}
      data-buttons={buttons.length > 0}
    >
      <ParallaxBackground anim="y1:x2n:z0" image={bg_1} className={classes.bg_1} />
      <ParallaxBackground anim="y1n:x0:z0" image={bg_2} className={classes.bg_2} />
      <ParallaxBackground anim="y2n:x0:z0" image={bg_3} className={classes.bg_3} />
      <ParallaxBackground anim="y1:x2:z0" image={bg_4} className={classes.bg_4} />
      <Container fluid="lg" className="px-0 px-lg-2">
        <div className="mb-4 px-3 px-lg-0">
          <EditableText path="title" tag="h2">
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </EditableText>
          <EditableHtml path="description">
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </EditableHtml>
        </div>
        <Carousel
          elementKey={prefix}
          slidesPerView={1.2}
          navigation={true}
          autoplay={true}
          breakpoints={{
            480: { slidesPerView: 1.6 },
            768: { slidesPerView: 2.4 },
            992: { slidesPerView: 3 },
            1500: { slidesPerView: 4 },
          }}
          items={jobs.map((post) => (
            <JobCard {...post} />
          ))}
        />
        {buttons && (
          <Stack className="flex-row justify-content-between px-4">
            <div />
            <Buttons className="flex-grow-0" buttons={buttons} />
          </Stack>
        )}
      </Container>
    </div>
  );
}
